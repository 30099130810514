import { formatPrice } from "./utils";

export const addToCart = ({
  offer,
  price,
  choices,
  quantity,
  canAddToCart,
  elementChoicesNames,
  remainingQuantity,
}) => ({
  offer: offer || "",
  choices: choices || [],
  quantity: quantity || 1,
  canAddToCart: canAddToCart || false,
  price: price || null,
  elementChoicesNames: elementChoicesNames || [],
  formattedPrice: price
    ? quantity
      ? formatPrice(price * quantity)
      : formatPrice(price)
    : null,
  remainingQuantity,
  additionalPrice: 0,
  activeElementAlert: null,
  selectOffer(offer, price, choices) {
    this.offer = offer;
    (this.choices = choices), (this.canAddToCart = !choices.length);
    this.price = price;
    this.refreshPrice()

    Array.from(document.querySelectorAll(`.dishtype-radio:not(.${offer})`)).forEach(
      (el) => {
        el.checked = false;
        el.disabled = true;
      }
    );
    choices.length &&
      Array.from(document.querySelectorAll(`.dishtype-radio.${offer}`)).forEach(
        (el) => {
          el.disabled = false;
        }
      );
  },
  selectChoice(typeId, dishId, additionalPrice) {
    this.choices = this.choices.map((c) =>
      c.type_id === typeId
        ? { ...c, id: dishId, additionalPrice: additionalPrice || 0 }
        : { ...c }
    );
    this.canAddToCart = this.choices.every((c) => !!c.id);
    this.additionalPrice = additionalPrice || 0;
    this.refreshPrice();
  },
  getAdditionalPrice() {
    return this.choices.length
      ? this.choices
          .filter((c) => !!c.additionalPrice)
          .map((c) => c.additionalPrice)
          .reduce((sum, p) => sum + p, 0)
      : this.additionalPrice;
  },
  refreshPrice() {
    this.formattedPrice =
      this.price &&
      this.quantity &&
      formatPrice((this.price + this.getAdditionalPrice()) * this.quantity);
  },
  add() {
    this.quantity++;
    this.refreshPrice();
  },
  remove() {
    this.quantity--;
    this.refreshPrice();
  },
  validate(evt) {
    this.elementChoicesNames.forEach((name) => {
      const isChecked = !!document.querySelector(
        `input[name="${name}"]:checked`
      );
      if (!isChecked) {
        this.activeElementAlert = name;
        evt.preventDefault();
        return;
      }
    });

    this.activeElementAlert &&
      this.$nextTick(() =>
        this.$refs[`alert-${this.activeElementAlert}`].scrollIntoView({
          behaviour: "smooth",
        })
      );
  },
});

export const selectBrunch = ({ choices, unitCentPrice }) => {
  const initialData = choices.reduce((acc, current) => {
    acc[current.key] = {quantity: 0, additionalUnitPrice: current.additional_price};
    return acc;
  }, {});

  return {
    ...initialData,
    unitCentPrice,
    totalCentPrice: 0,
    totalQuantity: 0,
    totalFormattedPrice: "",
    add(key) {
      this[key].quantity++;
      this.totalCentPrice += (this.unitCentPrice + this[key].additionalUnitPrice)
      this.totalQuantity++
      this.refreshTotal();
    },
    remove(key) {
      this[key].quantity--;
      this.totalCentPrice -= (this.unitCentPrice + this[key].additionalUnitPrice)
      this.totalQuantity--
      this.refreshTotal();
    },
    refreshTotal() {
      this.totalFormattedPrice = formatPrice(this.totalCentPrice);
    },
  };
};

export const selectCanteen = ({ offers, mainDishTypeId, currentDishId }) => ({
  currentDishId,
  offers,
  mainDishTypeId,
  offer: null,
  offerId: null,
  selectedDishtypes: [],
  quantity: 1,
  mainDishSelected: false,
  formattedPrice: null,
  init() {
    this.selectChoice(this.mainDishTypeId, this.currentDishId);
  },
  selectChoice(typeId, dishId) {
    if (dishId === null) {
      this.selectedDishtypes = this.selectedDishtypes.filter(
        (id) => id !== typeId
      );
    } else if (!this.selectedDishtypes.includes(typeId)) {
      this.selectedDishtypes = [...this.selectedDishtypes, typeId];
    }
    this.offer = this.offers.find(
      (o) =>
        o.types_ids.length === this.selectedDishtypes.length &&
        this.selectedDishtypes.every((id) => o.types_ids.includes(id))
    );
    this.offerId = this.offer && (
      this.offer.id === 'alone' ? null : this.offer.id
    )

    this.mainDishSelected = this.selectedDishtypes.includes(
      this.mainDishTypeId
    );
    this.refreshPrice();
  },
  refreshPrice() {
    this.formattedPrice =
      this.offer &&
      this.quantity &&
      formatPrice(this.offer.cent_price * this.quantity);
  },
  add() {
    this.quantity++;
    this.refreshPrice();
  },
  remove() {
    this.quantity--;
    this.refreshPrice();
  },
});
