import Alpine from "alpinejs";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import initHandlers from './handlers'
import { summaryItem, summaryCutlery, summaryOffer } from "./summary.js"
import { addToCart, selectBrunch, selectCanteen } from "./cart.js"
import { shipping, stripeHandler, changeBillingAddress } from "./checkout.js"
import { giftPayment } from "./gift.js";

Sentry.init({
  dsn: process.env.NODE_ENV === 'production' 
    ? "https://be367bf91f254d05be536bd14c26a7f2@o4504191802671104.ingest.sentry.io/4504191939903488"
    : null,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

initHandlers()

window.Alpine = Alpine;

Alpine.data('summaryItem', summaryItem);
Alpine.data('summaryCutlery', summaryCutlery);
Alpine.data('summaryOffer', summaryOffer);
Alpine.data('addToCart', addToCart);
Alpine.data('selectBrunch', selectBrunch);
Alpine.data('selectCanteen', selectCanteen);
Alpine.data('shipping', shipping);
Alpine.data('payment', stripeHandler);
Alpine.data('changeBillingAddress', changeBillingAddress);
Alpine.data('giftPayment', giftPayment);

Alpine.start();

window.htmx = require("htmx.org");

